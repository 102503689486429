// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';

import 'jquery-ui/ui/widgets/datepicker.js';
import 'babel-runtime/core-js.js';

// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';

/**
 * Import scripts from Custom Divi blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/divi/example-block/index.js';

/**
 * Import scripts from Custom Elementor widgets
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/elementor/example-widget/index.js';

/**
 * Import scripts from Custom ACF Gutenberg blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/gutenberg/acf-example-block/index.js';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

// Dynamic height change for scroll section
function dunamicHeight() {
  $('.custom-height').each(function () {
    let heightToTop = $(this).position().top;
    let totalHeight = $(window).height() - heightToTop;

    $(this).css('height', totalHeight - 40);
  });

  // $('.custom-height-quote-list').each(function () {
  //   let heightToTopQuoteList = $(this).position().top;
  //   let totalHeightQouteList = $(window).height() - heightToTopQuoteList;
  //   $(this).css('height', totalHeightQouteList - 400);
  // });

  // $('.custom-height-quote-list-without-login').each(function () {
  //   let heightToTopQuoteListNotLogin = $(this).position().top;
  //   let totalHeightQuoteListNotLogin =
  //     $(window).height() - heightToTopQuoteListNotLogin;
  //   $(this).css('height', totalHeightQuoteListNotLogin - 300);
  // });

  $('.custom-height-account').each(function () {
    let heightToTop = $(this).position().top;
    let totalHeight = $(window).height() - heightToTop;
    $(this).css('height', totalHeight - 65);
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  $('#datepicker').datepicker({
    minDate: '+30d',
    onSelect: function () {
      let label = $(this).parents('fieldset').find('label');
      label.addClass('active');
    },
  });

  // Menu animation
  $('.menu-icon').on('click', function () {
    let navbar = $('#main-menu');

    if ($(this).hasClass('is-active')) {
      navbar.css('opacity', 1);

      $(document).mouseup(function (e) {
        if ($(window).width() > '640') {
          if (
            !navbar.is(e.target) &&
            navbar.has(e.target).length === 0 &&
            !$('.menu-icon').is(e.target) &&
            $('.menu-icon').has(e.target).length === 0
          ) {
            navbar.css('opacity', 0);
            setTimeout(function () {
              navbar.css('display', 'none');
            }, 500);
            $('.menu-icon').removeClass('is-active');
          }
        }
      });
    }
  });

  if ($('body').hasClass('page-template-template-home')) {
    $('#main-menu li:not(.has-dropdown) a').click(function () {
      let navbar = $('#main-menu');
      navbar.css('opacity', 0);
      setTimeout(function () {
        navbar.css('display', 'none');
      }, 500);
      $('.menu-icon').removeClass('is-active');

      $('section').each(function () {
        if ($(this).isInViewport()) {
          var currentWrapper = $(this).find('.wrapper');
          currentWrapper.css({
            opacity: '0',
            position: 'fixed',
            transition: 'all 0.6s ease-in',
          });

          $('.wrapper')
            .not(currentWrapper)
            .css({ opacity: '0', transition: 'all 0s ease-in' });
          setTimeout(function () {
            $('.wrapper').css({ opacity: '1', position: 'static' });
          }, 800);
        }
      });
    });
  }

  $('.button-print').on('click', function () {
    window.print();
  });

  //Popup fancybox
  $('[data-fancybox]').fancybox({});

  $('input').each(function () {
    if ($(this).val().length > 0) {
      $('input')
        .parents('.woocommerce-form-row')
        .find('label')
        .addClass('active');
    }
  });

  // Animation of input labels in custom form
  $('fieldset input').on('focus', function () {
    let label = $(this).parents('fieldset').find('label');
    label.addClass('active');
  });

  $('fieldset input').on('focusout', function () {
    let label = $(this).parents('fieldset').find('label');
    if ($(this).val() == 0) {
      label.removeClass('active');
    }
  });

  // Animation of input labels in gravity form
  $('.gform_wrapper input').on('focus', function () {
    let label = $(this).parents('.gfield').find('label');
    label.addClass('active');
  });

  $('.gform_wrapper input').on('focusout', function () {
    let label = $(this).parents('.gfield').find('label');
    if ($(this).val() == '') {
      label.removeClass('active');
    }
  });

  // Animation of textarea labels in gravity form
  $('.gform_wrapper textarea').on('focus', function () {
    let label = $(this).parents('.gfield').find('label');
    label.addClass('active');
  });

  $('.gform_wrapper textarea').on('focusout', function () {
    let label = $(this).parents('.gfield').find('label');
    if ($(this).val() == '') {
      label.removeClass('active');
    }
  });

  // Animation of input labels in woocommerce form
  $('.woocommerce-Input').on('focus', function () {
    let label = $(this).parents('.woocommerce-form-row').find('label');
    let iconLock = $(this)
      .parents('.woocommerce-form-row')
      .find('.input-wrap-password');
    let showPassword = $(this)
      .parents('.woocommerce-form-row')
      .find('.show-password-input');

    label.addClass('active');
    iconLock.removeClass('icon-lock');
    showPassword.addClass('show');
  });

  $('.woocommerce-Input').on('focusout', function () {
    let label = $(this).parents('.woocommerce-form-row').find('label');
    let iconLock = $(this)
      .parents('.woocommerce-form-row')
      .find('.input-wrap-password');
    let showPassword = $(this)
      .parents('.woocommerce-form-row')
      .find('.show-password-input');
    if ($(this).val() == '') {
      label.removeClass('active');
      iconLock.addClass('icon-lock');
      showPassword.removeClass('show');
    }
  });

  var password = document.getElementById('reg_password'),
    confirm_password = document.getElementById('confirm_reg_password');

  function validatePassword() {
    if (password.value != confirm_password.value) {
      confirm_password.setCustomValidity("Passwords Don't Match");
    } else {
      confirm_password.setCustomValidity('');
    }
  }

  if (password) {
    password.onchange = validatePassword;
    confirm_password.onkeyup = validatePassword;
  }

  let a = true;
  $('a[href*=\\#]:not([href=\\#])').click(function () {
    if (
      location.pathname.replace(/^\//, '') ==
        this.pathname.replace(/^\//, '') ||
      location.hostname == this.hostname
    ) {
      if ($(this).parents().hasClass('tabs') === false) {
        var target = $(this.hash);

        target = target.length
          ? target
          : $('[name=' + this.hash.slice(1) + ']');

        if (target.length) {
          $('html,body').animate(
            {
              scrollTop: target.offset().top,
            },
            800,
            function () {
              a = true;
            }
          );
          return false;
        }
      }
    }
  });

  var scrollableElement = document.body;
  scrollableElement.addEventListener('wheel', checkScrollDirection);

  $.fn.isInViewport = function () {
    var elementTop = $(this).offset().top;
    var elementBottom = elementTop + $(this).outerHeight();

    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
  };

  // function clickPrev(elem) {
  //   if (elem.isInViewport()) {
  //     $(this).find('.to-prev-section').click();
  //     return true;
  //   }
  // }
  //
  // function clickNext(elem) {
  //   if (elem.isInViewport()) {
  //     $(this).find('.to-next-section').click();
  //     return true;
  //   }
  // }

  function checkScrollDirection(event) {
    if ($('body').hasClass('page-template-template-home')) {
      if (a == true) {
        if (event.wheelDelta > 100) {
          $('section').each(function () {
            if ($(this).isInViewport()) {
              a = false;
              $(this).find('.to-prev-section').click();
            }
          });
        } else {
          $('section').each(function () {
            if ($(this).isInViewport()) {
              a = false;
              $(this).find('.to-next-section').click();
            }
          });
        }
      }
    }
  }

  $('#venues').on('scroll', function () {
    if ($(this).scrollTop() == 0) {
      $(this).find('.to-prev-section').click();
    }
  });

  // Animation on scroll to next section
  $('.to-next-section').on('click', function () {
    let currentHref = $(this).attr('href').replace('#', '');
    let currentSection = $(this).parents('.wrapper');
    currentSection.css({
      opacity: '0',
      position: 'fixed',
      transition: 'all 0.6s ease-in',
    });
    setTimeout(function () {
      currentSection.css({
        opacity: '1',
        position: 'static',
        transition: 'all 0s ease-in',
      });
    }, 800);
    /////////////////////////////////
    $('section').each(function () {
      if ($(this).attr('id') == currentHref) {
        let nextSection = $(this).find('.wrapper');
        nextSection.css({ opacity: '0' });
        setTimeout(function () {
          nextSection.css({ opacity: '1' });
        }, 800);
      }
    });
  });

  $('.to-prev-section').on('click', function () {
    let currentHref = $(this).attr('href').replace('#', '');
    let currentSection = $(this).parents('.wrapper');
    currentSection.css({
      opacity: '0',
      position: 'fixed',
      transition: 'all 0.6s ease-in',
    });
    setTimeout(function () {
      currentSection.css({
        opacity: '1',
        position: 'static',
        transition: 'all 0s ease-in',
      });
    }, 800);
    /////////////////////////////////
    $('section').each(function () {
      if ($(this).attr('id') == currentHref) {
        let nextSection = $(this).find('.wrapper');
        nextSection.css({ opacity: '0' });
        setTimeout(function () {
          nextSection.css({ opacity: '1' });
        }, 800);
      }
    });
  });

  $('.change-form').on('click', function () {
    $('.form-wrap').each(function () {
      $(this).toggleClass('hiden');
    });
  });

  let typeTaxonomy = $('.quote-bilder .tabs li.is-active a').attr(
    'data-taxonomy'
  );
  if (typeTaxonomy) {
    window.history.pushState('data', document.title, '?type=' + typeTaxonomy);

    $('.quote-bilder .tabs li').on('click', function () {
      let newTypeTaxonomy = $(this).find('a').attr('data-taxonomy');
      window.history.replaceState('data', '', '?type=' + newTypeTaxonomy);

      $('.items-box li').remove();
      createLocalStorageOrder();
      calculateTotalPriceInPopup();

      let newCountGuest = $(
        '#best-seler-popup_' + newTypeTaxonomy + ' #number-of-guests-popup'
      ).val();
      $('.quote #number-of-guests').val(newCountGuest);
      $('.quote #number-of-guests').parent().find('label').addClass('active');
    });
  }

  /**
   * Order Calculator
   */
  /* global ajax_object */
  $('.menu-products-list .product').on('click', function () {
    $(this).addClass('checked');
    if ($(this).hasClass('has-attr-popup')) {
      let attrPopup = $(this).find('.attr-popup');
      let attrUrl = '#attr-popup-' + attrPopup.data('product-popup');
      $.fancybox.open({
        src: attrUrl,
        type: 'inline',
      });

      $(this).removeClass('has-attr-popup');
    }

    // Add product to quote from product list
    let productSelect = $(this);
    let productId = productSelect.attr('id');
    if (productSelect.hasClass('active') === false) {
      productSelect.addClass('active');

      let productCatName = $('.tabs-title.is-active').find('a').text();
      let productCatSlug = $('.tabs-title.is-active')
        .find('a')
        .attr('data-category-slug');
      let typeQuote = checkTypeQuote();

      $.ajax({
        url: ajax_object.ajax_url,
        data: {
          action: 'add_product',
          product_id: productId,
          product_cat_name: productCatName,
          product_cat_slug: productCatSlug,
          taxonomy: typeQuote,
        },
        type: 'post',
        success: function (result) {
          $('.quote .items-box .product-item').each(function () {
            if ($(this).attr('data-term') == productCatSlug) {
              $(this).append(result.html);
              $(this).css('display', 'block');

              return false;
            }
          });

          productSelect.removeClass('checked');

          productExistenceCheck();
          createLocalStorageProduct();
        },
        error: function (result) {
          console.warn(result);
        },
      });
    } else {
      // Add product to quote from product list if this product already exists
      $('.items-box li').each(function () {
        if ($(this).data('product') == productId) {
          setTimeout(function () {
            productSelect.removeClass('checked');
          }, 2000);

          let inputQtyProduct = $(this).find('.qty input').val();
          let productPrice = $(this).attr('data-price');
          let productStep = $(this).find('.qty input').attr('step');

          let newValue = Number(inputQtyProduct) + Number(productStep);
          $(this).find('.qty input').val(Number(newValue));

          let price = $(this).find('.price span');
          let newPrice = Number(productPrice) + Number(price.text());

          $(this).find('.price span').html(newPrice);

          calculateTotalPrice();
          createLocalStorageProduct();
        }
      });
    }
  });

  // Show/hide submit button
  function productExistenceCheck() {
    if ($('.quote .items-box li').length == 0) {
      $('.quote .price-submit-wrap').css('display', 'none');
      $('.quote .guest-input-wrap').css('display', 'none');
    } else {
      $('.quote .price-submit-wrap').css('display', 'block');
      $('.quote .guest-input-wrap').css('display', 'block');
      calculateTotalPrice();
    }
  }

  // Calculate total price
  function calculateTotalPrice() {
    let arrayPrice = [];
    let price = $('.quote .items-box li .price span');
    price.each(function () {
      arrayPrice.push($(this).text());
    });

    let total = 0;
    for (var i = 0; i < arrayPrice.length; i++) {
      total += Number(arrayPrice[i]);
    }
    $('.price-submit-wrap .price span').html(total);

    let taxes = (total / 100) * 15;
    $('.price-submit-wrap .taxes span').html(taxes.toFixed(2));

    let summary = total + taxes;
    $('.price-submit-wrap .summary span').html(summary.toFixed(2));
  }

  productExistenceCheck();

  // Calculate price if change count
  $(document).on('click', '.qty span', function () {
    let qty = $(this).siblings('input');
    let curentProduct = qty.parents('li');
    let multipleProducts = $('.items-box li*[data-multiple = "true"]');
    let min = parseFloat(qty.attr('min'));
    let step = parseFloat(qty.attr('step'));
    let val = parseFloat(qty.val());

    let newCount = '';
    multipleProducts.each(function () {
      newCount = Number(newCount) + Number($(this).find('.qty input').val());
    });

    if ($(this).is('.plus')) {
      qty.val(val + step);
    } else {
      if (min >= val) {
        qty.val(min);
      } else if (val > min) {
        /////
        if (newCount == 100) {
          let closestMultipleProduct = curentProduct.siblings(
            '*[data-multiple = "true"]'
          );
          closestMultipleProduct.each(function (i) {
            if (i === 0) {
              let input = $(this).find('.qty input');
              let inputValue = input.val();
              input.val(Number(inputValue) + Number(step));
            }
          });
          qty.val(val - step);
        } else {
          qty.val(val - step);
        }
        ////
      }
    }

    qty.trigger('change');
    let newValue = qty.val();

    if (qty.parents('.attr-popup').length > 0) {
      qty.parents('.attr-popup').attr('data-count', newValue);
    }

    let price = qty.parents('li').find('.price span');
    let priceVal = qty.parents('li').data('price');
    let newPrice = Number(newValue) * Number(priceVal);
    price.html(newPrice);

    resettingMultipleProduct();
    createLocalStorageProduct();
    calculateTotalPrice();
    calculateTotalCountInPopup();
  });

  function resettingMultipleProduct() {
    $('.items-box li').each(function () {
      if ($(this).attr('data-multiple')) {
        let curentProduct = $(this).attr('data-product');
        let curentCount = $(this).find('.qty input').val();
        $('.multiselect-popup')
          .find('#' + curentProduct)
          .find('.qty-multi input')
          .val(curentCount);
      }
    });
  }

  // Add product in multiple popup
  $(document).on('click', '.qty-multi span', function () {
    let qty = $(this).siblings('input');
    let min = parseFloat(qty.attr('min'));
    let step = parseFloat(qty.attr('step'));
    let val = parseFloat(qty.val());

    $('.total-count-value').removeClass('attention');

    if ($(this).is('.plus')) {
      qty.val(val + step);
    } else {
      if (min >= val) {
        qty.val(min);
      } else if (val > min) {
        qty.val(val - step);
      }
    }

    calculateTotalCountInPopup();
  });

  $('.multiselect-popup .close-multi-popup').on('click', function () {
    let value = parseFloat($('.total-count-value').text());
    let typeQuote = checkTypeQuote();

    if (typeQuote == 'product_cat') {
      var countCanape = 100;
    } else {
      countCanape = 50;
    }

    if (Number(value) >= Number(countCanape)) {
      $('.total-count-value').removeClass('attention');

      let multiProduct = $('.multiselect-popup').find('.multiselect-product');
      multiProduct.each(function () {
        let countVal = $(this).find('.qty-multi input').val();
        let curentProductId = $(this).attr('id');

        if (countVal > 0) {
          $('.items-box li').each(function () {
            if ($(this).data('product') == curentProductId) {
              // Add product to quote from product list if this product already exists
              let productPrice = $(this).attr('data-price');
              $(this).find('.qty input').val(countVal);
              let newPrice = Number(productPrice) * Number(countVal);
              $(this).find('.price span').html(newPrice);

              createLocalStorageProduct();
            }
          });
        }
      });

      multiProduct.each(function () {
        let countVal = $(this).find('.qty-multi input').val();
        let curentProductId = $(this).attr('id');
        let productCatName = $('.tabs-title.is-active').find('a').text();
        let productCatSlug = $('.tabs-title.is-active')
          .find('a')
          .attr('data-category-slug');
        let a = $('.items-box').find(
          '*[data-product = "' + curentProductId + '"]'
        );

        if (countVal > 0) {
          if (a.length == 0) {
            $.ajax({
              url: ajax_object.ajax_url,
              data: {
                action: 'add_product',
                product_id: curentProductId,
                product_count: countVal,
                product_cat_name: productCatName,
                product_cat_slug: productCatSlug,
                multiple: true,
              },
              type: 'post',
              success: function (result) {
                $('.quote .items-box .product-item').each(function () {
                  if ($(this).attr('data-term') == productCatSlug) {
                    $(this).append(result.html);
                    $(this).css('display', 'block');

                    return false;
                  }
                });

                productExistenceCheck();
                createLocalStorageProduct();
              },
              error: function (result) {
                console.warn(result);
              },
            });
          }
        } else {
          a.remove();
          createLocalStorageProduct();
        }
      });

      $.fancybox.close({
        src: 'multiselect-popup',
        type: 'inline',
      });
    } else {
      $('.total-count-value').addClass('attention');
    }
  });

  // Add best seller order
  $('.close-bestseller-popup').on('click', function () {
    let bestsellerPopup = $(this).parents('.bestseller-popup');
    let multiProduct = bestsellerPopup.find('.multiselect-product');
    let typeQuote = checkTypeQuote();
    localStorage.removeItem(typeQuote);
    let arrayProduct = [];
    let qty = bestsellerPopup.find('#number-of-guests-popup').val();
    if (qty < 50) {
      qty = 50;
    }

    multiProduct.each(function () {
      let curentProductId = $(this).attr('id');
      let minVal = $(this).find('.qty-multi input').attr('min');
      let price = $(this).find('.price span').text();

      arrayProduct.push({
        count: qty,
        min_val: minVal,
        id: curentProductId,
        price: price,
        bestseller: 'bestseller',
      });
      localStorage.setItem(typeQuote, JSON.stringify(arrayProduct));
    });

    if (localStorage.getItem(typeQuote)) {
      let newArrayProduct = localStorage.getItem(typeQuote);
      let parsOBJ = JSON.parse(newArrayProduct);

      $.ajax({
        url: ajax_object.ajax_url,
        data: {
          action: 'add_product_from_local_storage_bestseller',
          products: parsOBJ,
          taxonomy: typeQuote,
        },
        type: 'post',
        success: function (result) {
          $('.quote .items-box').html(result.html);

          let li = $('.quote .items-box li');
          li.each(function () {
            let price = $(this).attr('data-price');
            let count = $(this).find('.qty input').val();
            $(this)
              .find('.price span')
              .text(Number(price) * Number(count));
          });

          createLocalStorageProduct();
          productExistenceCheck();

          let newCountGuest = $(
            '#best-seler-popup_' + typeQuote + ' #number-of-guests-popup'
          ).val();
          $('.quote #number-of-guests').val(newCountGuest);
          $('.quote #number-of-guests')
            .parent()
            .find('label')
            .addClass('active');
        },
        error: function (result) {
          console.warn(result);
        },
      });
    }

    $.fancybox.close({
      src: '#best-seler-popup',
      type: 'inline',
    });
  });

  let gouestInputPopup = $('.bestseller-popup').find('#number-of-guests-popup');
  gouestInputPopup.on('change', function () {
    let curentNumberGuest = $(this).val();
    let minVal = $(this).attr('min');

    if (Number(curentNumberGuest) < Number(minVal)) {
      $(this).val(minVal);
    }
  });

  $('#number-of-guests').on('change', function () {
    let curentNumberGuest = $(this).val();
    let minVal = $(this).attr('min');

    if (Number(curentNumberGuest) < Number(minVal)) {
      $(this).val(minVal);
      curentNumberGuest = Number(minVal);
    }

    let li = $('.quote .items-box li');
    li.each(function () {
      let price = $(this).attr('data-price');
      let min = $(this).find('.qty input').attr('min');
      $(this)
        .find('.qty input')
        .val(min * curentNumberGuest);

      $(this)
        .find('.price span')
        .text(price * curentNumberGuest * min);
    });

    calculateTotalPrice();
  });

  $('.own-package').on('click', function () {
    if ($('.items-box').find('.bestseller').length > 0) {
      $.fancybox.open({
        src: '#bestseller-new-update',
        type: 'inline',
      });
    } else {
      $.fancybox.open({
        src: '#bestseller-build-own',
        type: 'inline',
      });
    }
  });

  $('.bestseller-popup .guest-wrap input').on('change mousewheel', function () {
    let countGuest = $(this).val();
    let multiProduct = $(this)
      .parents('.bestseller-popup')
      .find('.multiselect-product');
    multiProduct.each(function () {
      let minVal = $(this).find('.qty-multi input').attr('min');

      $(this)
        .find('.qty-multi input')
        .val(Number(minVal) * Number(countGuest));
    });

    $(this).attr('data-new-val', countGuest);

    calculateTotalPriceInPopup();
  });

  // Calculate total price in best seller popup
  function calculateTotalPriceInPopup() {
    let typeQuote = checkTypeQuote();
    let a = 'best-seler-popup_' + typeQuote;
    let totalCount = $('#' + a).find('.total-price-value');
    let newCount = '';
    $('#' + a)
      .find('.qty-multi input')
      .each(function () {
        let price = $(this)
          .parents('.calculate-wrap')
          .find('.price span')
          .text();
        newCount = Number(newCount) + Number($(this).val()) * Number(price);
      });

    totalCount.html(newCount);
  }

  calculateTotalPriceInPopup();

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  $('.attr-popup .button').on('click', function () {
    let popup = $(this).parents('.attr-popup');
    let dataProduct = popup.data('product-popup');

    let textArea = $.trim(popup.find('textarea').val());

    let selectOptionValue = [];
    let selectName = [];
    let attr = popup.find('.term-product-select');

    // if select have own price
    let attrVarPrice = attr.find('option:selected').attr('data-price');

    attr.each(function () {
      selectOptionValue.push($(this).find('option:selected').val());
      selectName.push($(this).attr('id').replace('attr-pa_', ''));
    });

    let li = $(document).find('.items-box').find('li');

    li.each(function () {
      if ($(this).data('product') == dataProduct) {
        let a = selectOptionValue.join(',');
        $(this).attr('data-term', a);
        $(this).attr('data-name', selectName);

        if (textArea.length > 0) {
          $(this).attr('data-text', textArea);
        }

        let price = $(this).find('.price span');
        let oldValue = $(this).find('.qty input').val();
        let newValue = popup.attr('data-count');
        $(this).find('.qty input').val(newValue);

        if (attrVarPrice) {
          $(this).attr('data-price', attrVarPrice);

          let newPrice = Number(newValue) * Number(attrVarPrice);
          price.html(newPrice);
        } else {
          let newPrice =
            Number(newValue) * (Number(price.text()) / Number(oldValue));
          price.html(newPrice);
        }
      }
    });

    createLocalStorageProduct();
    calculateTotalPrice();
  });

  function createLocalStorageProduct() {
    let typeQuote = checkTypeQuote();
    let arrayProduct = [];
    $('.items-box li').each(function () {
      let qty = $(this).find('.qty input').val();
      let minVal = $(this).find('.qty input').attr('min');
      let price = $(this).find('.price span').text();
      let name = $(this).attr('data-name');
      let categoryName = $(this).attr('data-category-name');
      let categorySlug = $(this).attr('data-category-slug');
      let attr = $(this).attr('data-term');
      let text = $(this).data('text');
      let multiple = $(this).attr('data-multiple');
      let bestseller = $(this).attr('class');
      arrayProduct.push({
        count: qty,
        min_val: minVal,
        id: $(this).data('product'),
        price: price,
        category_name: categoryName,
        category_slug: categorySlug,
        name: name,
        attr: attr,
        text: text,
        multiple: multiple,
        bestseller: bestseller,
      });
    });
    localStorage.setItem(typeQuote, JSON.stringify(arrayProduct));
  }

  let typeQuote = checkTypeQuote();
  if (!localStorage.getItem('checkedLocalStorage' + typeQuote)) {
    localStorage.setItem(
      'checkedLocalStorage' + typeQuote,
      'checked_' + typeQuote
    );
    localStorage.removeItem(typeQuote);
  }

  // Create localstorage order
  function createLocalStorageOrder() {
    let typeQuote = checkTypeQuote();

    if (localStorage.getItem(typeQuote)) {
      let newArrayProduct = localStorage.getItem(typeQuote);
      let parsOBJ = JSON.parse(newArrayProduct);

      var promises = [];
      $.each(parsOBJ, function (i, val) {
        let activeProducts = $('#' + val.id).addClass('active');
        activeProducts.removeClass('has-attr-popup');

        promises.push(
          $.ajax({
            url: ajax_object.ajax_url,
            data: {
              action: 'add_product_from_local_storage',
              taxonomy: typeQuote,
              count: val.count,
              min_val: val.min_val,
              id: val.id,
              price: val.price,
              category_name: val.category_name,
              category_slug: val.category_slug,
              name: val.name,
              attr: val.attr,
              text: val.text,
              multiple: val.multiple,
              bestseller: val.bestseller,
            },
            type: 'post',
            success: function (result) {
              $('.quote .items-box .product-item').each(function () {
                if ($(this).attr('data-term') == val.category_slug) {
                  $(this).append(result.html);
                  $(this).css('display', 'block');

                  return false;
                }
              });

              productExistenceCheck();
            },
            error: function (result) {
              console.warn(result);
            },
          })
        );
      });

      $.when.apply($, promises).then(function () {
        //Change count multiple product in popup
        $('.items-box li').each(function () {
          if ($(this).attr('data-multiple') == 'true') {
            let dataProduct = $(this).attr('data-product');
            let compliantPopupProduct = $('.multiselect-popup #' + dataProduct);
            let productCount = $(this).find('.qty input').val();
            compliantPopupProduct.find('.qty-multi input').val(productCount);
          }
        });
        let totalCount = $('.total-count-value');
        let newCount = '';
        $('.multiselect-popup .qty-multi input').each(function () {
          newCount = Number(newCount) + Number($(this).val());
        });

        totalCount.html(newCount);

        resettingMultipleProduct();
      });
    }
  }

  createLocalStorageOrder();

  function checkTypeQuote() {
    let searchParams = new URLSearchParams(window.location.search);
    let typeEvent = '';
    if (searchParams.has('type')) {
      typeEvent = searchParams.get('type');
    } else {
      typeEvent = 'product_cat';
    }

    return typeEvent;
  }

  // Calculate total count in multiple popup
  function calculateTotalCountInPopup() {
    let totalCount = $('.total-count-value');
    let newCount = '';
    $('.multiselect-popup .qty-multi input').each(function () {
      newCount = Number(newCount) + Number($(this).val());
    });

    totalCount.html(newCount);
  }

  // Remove product on click in quote
  $(document).on('click', '.items-box li .hover-wrap .image-wrap', function () {
    let curentProduct = $(this).parents('li');

    if (curentProduct.attr('class') == 'bestseller') {
      $('.items-box li').each(function () {
        $(this).siblings('.product-subcategory').remove();
        $(this).remove();
      });

      createLocalStorageProduct();
    }

    // Resetting the quantity of products from multiple popup
    if (curentProduct.attr('data-multiple') == 'true') {
      let closestMultipleProduct = curentProduct.siblings(
        'li*[data-multiple = "true"]'
      );

      // let qty = curentProduct.find('input');
      let idProduct = curentProduct.attr('data-product');
      $('.multiselect-popup')
        .find('#' + idProduct)
        .find('.qty-multi input')
        .val(0);

      let newCount = '';
      closestMultipleProduct.each(function () {
        newCount = Number(newCount) + Number($(this).find('.qty input').val());
      });

      if (newCount < 100) {
        closestMultipleProduct.each(function (i) {
          let closestProduct = $(this);
          let input = closestProduct.find('.qty input');
          if (i === 0) {
            let inputValue = input.val();
            input.val(Number(inputValue) + Number(100 - newCount));
          }
        });
      }

      resettingMultipleProduct();
      let dataCurentProduct = curentProduct.attr('data-product');
      $('.multiselect-popup')
        .find('#' + dataCurentProduct)
        .find('.qty-multi input')
        .val(0);

      calculateTotalCountInPopup();
    }

    let removedLi = $(this).parents('li');
    removedLi.siblings('li');

    if (removedLi.siblings('li').length == 0) {
      removedLi.siblings('.product-subcategory').remove();
    }

    removedLi.remove();

    let dataProduct = curentProduct.data('product');

    createLocalStorageProduct();

    $('#' + dataProduct).removeClass('active');

    if ($('#' + dataProduct).find('.attr-popup').length > 0) {
      $('#' + dataProduct).addClass('has-attr-popup');
    }

    productExistenceCheck();
  });

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  // Сhecking the event order for correct filling
  $('.confirm-button').on('click', function () {
    let a = 1;
    let b = '';
    let eventTypeUrl = checkTypeQuote();
    let counter = 1;
    $('.tabs .category-product').each(function () {
      let catText = $(this).find('a').text();
      $('.items-box .product-subcategory').each(function () {
        if ($(this).text() == catText) {
          counter++;
        }
      });
      a++;
    });

    if (eventTypeUrl == 'station_package') {
      $('.items-box li').each(function () {
        if ($(this).attr('data-category-slug') == 'station') {
          b = +b + 1;
        }
      });
      if (b < 3) {
        $.fancybox.open({
          src: '#events-station-popup',
          type: 'inline',
        });
        return false;
      }
    }

    if (eventTypeUrl == 'cocktail_dinatoire') {
      $('.items-box li').each(function () {
        if ($(this).attr('data-category-slug' == 'cocktail_dinatoire')) {
          b = +b + 1;
        }
      });
      if (b < 10) {
        $.fancybox.open({
          src: '#events-dinatore-popup',
          type: 'inline',
        });
        return false;
      }
    }

    if (counter != a) {
      $.fancybox.open({
        src: '#events-confirm-popup',
        type: 'inline',
      });
    } else {
      $.fancybox.open({
        src: '#events-order-confirm',
        type: 'inline',
      });
    }
  });

  // Create order after submit
  $('.price-submit-wrap .create-order').on('click', function () {
    let productId = $('.quote li');

    let arrayProduct = [];
    productId.each(function () {
      const item = [];
      item.push($(this).data('product'));
      item.push($(this).find('.qty input').val());
      item.push($(this).attr('data-name'));
      item.push($(this).attr('data-term'));
      item.push($(this).attr('data-text'));

      arrayProduct.push(item);
    });

    if ($('body').hasClass('logged-in') == false) {
      if ($('input[name="user-name"]').val().length == 0) {
        $('input[name="user-name"]').addClass('attention');
        return;
      }
      var nameUser = $('input[name="user-name"]').val();

      if (
        $('input[name="user-email"]').val().length == 0 ||
        ($('input[name="user-email"]').val().length > 0 &&
          !isEmail($('input[name="user-email"]').val()))
      ) {
        $('input[name="user-email"]').addClass('attention');
        return;
      }
      $('input[name="user-email"]').removeClass('attention');
      var emailUser = $('input[name="user-email"]').val();
    }

    if ($('input[name="order-name"]').val().length == 0) {
      $('input[name="order-name"]').addClass('attention');
      return;
    }
    let nameOrder = $('input[name="order-name"]').val();

    let eventTypeUrl = checkTypeQuote();
    if (eventTypeUrl != 'product_cat') {
      if ($('input[name="datepicker"]').val().length == 0) {
        $('input[name="datepicker"]').addClass('attention');
        return;
      }
      var dateEvent = $('input[name="datepicker"]').val();

      if ($('input[name="event-type"]').val().length == 0) {
        $('input[name="event-type"]').addClass('attention');
        return;
      }
      var typeQuote = $('input[name="event-type"]').val();

      if ($('input[name="number-of-guests"]').val().length == 0) {
        $('input[name="number-of-guests"]').addClass('attention');
        return;
      }
      var numberGuests = $('input[name="number-of-guests"]').val();

      if ($('input[name="location"]').val().length == 0) {
        $('input[name="location"]').addClass('attention');
        return;
      }
      var locationEvent = $('input[name="location"]').val();

      // if ($('select[name="location"]').find('option:selected').length == 0) {
      //   $('select[name="location"]').addClass('attention');
      //   return;
      // }
      // var locationEvent = $('select[name="location"]')
      //   .find('option:selected')
      //   .val();
    }

    $.ajax({
      url: ajax_object.ajax_url,
      data: {
        action: 'create_order',
        event_type_url: eventTypeUrl,
        array_product: arrayProduct,
        name_order: nameOrder,
        user_email: emailUser,
        user_name: nameUser,
        date_event: dateEvent,
        type_event: typeQuote,
        number_guests: numberGuests,
        location: locationEvent,
      },
      type: 'post',
      success: function () {
        if ($('body').hasClass('logged-in')) {
          if (window.location.href.indexOf('/en/') > -1) {
            let url = window.location.origin + '/en/my-account';
            $(location).attr('href', url);
          } else {
            let url = window.location.origin + '/my-account';
            $(location).attr('href', url);
          }
        } else {
          $.fancybox.open({
            src: '#order-complete',
            type: 'inline',
          });
        }
      },
      error: function () {
        console.warn();
      },
    });
  });

  function isEmail(email) {
    var regex = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/; // eslint-disable-line
    return regex.test(email);
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }

  dunamicHeight();
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here
  dunamicHeight();

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
var btn = $('.to-top');

// var lastScrollTop = 0;
// var delta = 5;
$(window).on('scroll', function () {
  // jQuery code goes here

  // Add class to scroll-button
  if ($(window).scrollTop() > 500) {
    btn.addClass('show');
  } else {
    btn.removeClass('show');
  }
});

btn.on('click', function (e) {
  e.preventDefault();
  $('html, body').animate({ scrollTop: 0 }, 300);
});
